<template>
    <div class="showBaseTemplate_container">
        <CustomImage :src="data.content" />
    </div>
</template>
<script>
import CustomImage from '@/components/unit/customImage'
export default {
    name:"showBaseTemplate",
    props:{
        pageItem:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    data(){
        return {
            data:{
                content:""
            }
        }
    },
    components:{
        CustomImage
    },
   created(){
        this.initData()
    },
    methods:{
        initData(){
            let keys = Object.keys(this.data)
            keys.forEach((key,index)=>{
                this.data[key] =  this.pageItem.content[index].original_image
            })
        }
    }
}
</script>
<style>
.showBaseTemplate_container{
    height:100%;
    width:100%;
}
</style>